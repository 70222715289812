import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrl: "./about.component.scss",
})
export class AboutComponent implements OnInit {
  vendorLink = "https://www.boschrexroth.com";
  vendorAddress = [
    "Bosch Rexroth AG",
    "Bgm.-Dr.-Nebel-Str. 2",
    "97816 Lohr am Main",
    "GERMANY",
  ];

  constructor(private translate: TranslateService,
              private router: Router, 
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    const title = this.translate.instant("_AboutTitle");
  }

  onAboutFossClicked() {
    const queryParams = this.route.snapshot.queryParams;
    this.router.navigate(["/about/foss"], { queryParams });
  }

  onTermsOfUseClicked() {
    this.translate.get('_AboutTermsLink').subscribe(data => window.open(data, '_blank'));
  }
}