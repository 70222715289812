import { Directive } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[typeCodeMatNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useClass: TypeCodeMatNumberValidatorDirective,
      multi: true
    }
  ]
})
export class TypeCodeMatNumberValidatorDirective implements Validator {

  validator: ValidatorFn;
  constructor() {
    this.validator = this.typeCodeMatNumberValidator();
  }

  validate(c: UntypedFormControl) {
    return this.validator(c);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Helper validation, is used for...
  // => Keep previous error at keypress 
  //    This is needed to prevents the temporary disappearance of the validation error message during input at keypress event
  // => The main validation is done in the component generator.component.ts / generator-overview.component.ts in function initAutoComplete()
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  typeCodeMatNumberValidator(): ValidatorFn {
    return (control: UntypedFormControl) => {
       if (control.value != null && control.value !== "" && control.errors != null) {
         return control.errors;
       } else {
         return null;
       }
    };
  } 
}