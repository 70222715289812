/**
 * Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
 */
import { NGXLogger } from 'ngx-logger';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

import { SidebarNavItem, } from '@bci-web-core/core';
import { Observable } from 'rxjs';

import { NavigationService } from './_services/navigation.service';
import { ParameterService } from './_services/parameter.service';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { HelpMenuComponent } from './help-menu/help-menu.component';


@Component({
  selector: 'bci-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  // Pfad zu den JSON-Dateien
  sidebarLinks: SidebarNavItem[] = [];
  sidebarFooterLinks: SidebarNavItem[] = [];
  headerItems = [UserMenuComponent,
                 HelpMenuComponent];
  


  constructor(private titleService: Title,
              public translate: TranslateService,
              private parService: ParameterService,
              private navigationService: NavigationService) {
    // set language
    translate.use(parService.language);
    translate.addLangs(['en_US', 'de_DE']);
  }

  ngOnInit() {
    this.setApplicationTitle();
    this.getSidebarLinks().subscribe(sidebarLinks => this.sidebarLinks = sidebarLinks);
    this.getSidebarFooterLinks().subscribe(sidebarFooterLinks => this.sidebarFooterLinks = sidebarFooterLinks);
  }

  private setApplicationTitle() {
    this.translate.get('_Title')
        .subscribe(
        data => {
          this.titleService.setTitle(data);
        });
  }

  getSidebarLinks(orderId?: string): Observable<SidebarNavItem[]> {
    return this.navigationService.getNavigationItems();
  }

  getSidebarFooterLinks(): Observable<SidebarNavItem[]> {
    return this.navigationService.getNavigationFooterItems();
  }
}
