import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { NavigationService } from '../_services/navigation.service';

@Injectable({
  providedIn: 'root'
})

// this guard forces a redirection to the '/generator' route.
// It forwards all query params from the current route.
export class RedirectToGeneratorGuard  {

  constructor(private navigationService: NavigationService) {
  }

  canActivate() {
    this.navigationService.navigate('/generator');
    return true;
  }
}
