<!--
  ~ Copyright (C) 2019 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
  -->
<!-- <bci-app [sidebarLinks]="sidebarLinks"
         [sidebarFooterLinks]="sidebarFooterLinks"
         [applicationTitle]="'_AppTitleLine1'|translate">
  <router-outlet></router-outlet>
</bci-app> --> 

<rexroth-app [sidebarLinks]="sidebarLinks"
             [sidebarFooterLinks]="sidebarFooterLinks"
             [applicationTitle]="'_AppTitleLine1'|translate"
             [isCoreApp]="false"
             [options]="{ hideLanguageMenu: true, hidePageHeader: true }" 
             [headerItems]="headerItems">
  <router-outlet></router-outlet>
</rexroth-app>


