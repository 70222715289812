import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DEFINITIONS } from './definitions';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addGenMode(request));
  }

  addGenMode(request: HttpRequest<any>) {
    // get generator running mode
    let genmode = sessionStorage.getItem(DEFINITIONS.sessParGenMode);
    let eplanversion = sessionStorage.getItem(DEFINITIONS.sessParEplanVersion);

    switch(genmode) {
      // use genmode as it is
      case DEFINITIONS.modeEmbedded:
      case DEFINITIONS.modeBrowserEmbedded:
      case DEFINITIONS.modeStandalone:
        break;

      // add eplan version
      case DEFINITIONS.modeEplanEmbedded:
        genmode = (eplanversion) ? (genmode + ',' + eplanversion) : genmode
        break;

      // reinit genmode
      default:
        if ( window.location !== window.parent.location ) {
          genmode = DEFINITIONS.modeEmbedded;
        } else {
          genmode = DEFINITIONS.modeStandalone;
        }
        sessionStorage.setItem(DEFINITIONS.sessParGenMode, genmode);
        break;
    }

    return request.clone({
        setHeaders: {
          genmode: genmode
        }
    })
  }
}
