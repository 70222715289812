import { NGXLogger } from 'ngx-logger';
import { Component, HostListener, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';
import { NavigationService } from '../_services/navigation.service';
import { DEFINITIONS } from './../definitions';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})

export class UserMenuComponent implements OnInit {

  constructor( private logger: NGXLogger,
               private navigationService: NavigationService,
               private authService: AuthenticationService) { 

    this.logger.info('(user-menu.component) => constructor()'); 
  }

  ngOnInit(): void {
    this.checkLogin();
  }

  checkLogin(): void {
    sessionStorage.removeItem(DEFINITIONS.sessParEcadUser);
    sessionStorage.removeItem(DEFINITIONS.sessParEcadUserLoggedIn);
    this.isUserAuthenticated().subscribe(isAuthenticated => {
      this.logger.info('(user-menu.component) User... isAuthenticated => ' + isAuthenticated);
    });
  }

  ngOnDestroy(): void {
  }

  public get isUserManagementAvailable() {
    if( this.navigationService.getActiveUc() === 'admin') {
      return true;
    }
    return false;
  }

  public get isUserLoggedIn() {
    if( sessionStorage.getItem(DEFINITIONS.sessParEcadUserLoggedIn) === 'true') {
      return true;
    }
    return false;
  }

  public get getUsername() {
    return sessionStorage.getItem(DEFINITIONS.sessParEcadUser);
  }

  isUserAuthenticated(): Observable<boolean>{
    return this.authService.isAuthenticated.pipe( 
        map((isAuth: boolean) => {
          if( isAuth) {
            return true;
          } 
          return false;
        })
     )      
  } 

  onLoginClicked() {
    this.logger.info('Login...');   
    //call login and forward base64 encoded current url (used for redirection after login)
    window.open(DEFINITIONS.serverUrlGeneratorLogin + '?redirecturl=' + btoa(window.location.href), "_self")
  }

  onLogoutClicked() {
    this.logger.info('Logout...');   
    //call logout and forward base64 encoded current url (used for redirection after logout)
    window.open(DEFINITIONS.serverUrlGeneratorLogout + '?redirecturl=' + btoa(window.location.href), "_self")
  }

}

