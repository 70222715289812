import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectToGeneratorGuard } from './_guards/redirect-to-generator.guard';
import { GeneratorComponent } from './generator/generator.component';
import { AboutComponent } from './about/about.component';
import { AuthGuard } from './_guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [RedirectToGeneratorGuard],
    component: GeneratorComponent
  },
  {
    path: 'overview',
    loadChildren: () => import('./generator-overview/generator-overview.module').then(m => m.GeneratorOverviewModule)
  },
  {
    path: 'generator',
    component: GeneratorComponent
  },
  {
    path: 'orders/administration',
    canActivate: [AuthGuard],
    loadChildren: () => import('./order-administration/order-administration.module').then(m => m.OrderAdministrationModule)
  },
  {
    path: 'orders/:orderId',
    loadChildren: () => import('./order-tracker/order-tracker.module').then(m => m.OrderTrackerModule)
  },
  {
    path: 'about/foss',
    loadChildren: () => import('./foss-info-wrapper.module').then(m => m.FossInfoWrapperModule)
  },
  {
    path: 'about',
    component: AboutComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
