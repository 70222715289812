import { MatTreeModule } from '@angular/material/tree';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  hmr: false,

  //ngx-logger settings =>
  logLevel: NgxLoggerLevel.ERROR,
  enableSourceMaps: false,
  disableFileDetails: true,
  serverLogLevel: NgxLoggerLevel.OFF
  //serverLoggingUrl: 'http://https://ecad-configuration.boschrexroth.com/generator/api/v1/logging',
};
