import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DEFINITIONS } from '../definitions';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

type ICallback = (response: any) => void;


@Injectable({ providedIn: 'root' })

export class AuthenticationService {

  constructor(private logger: NGXLogger,
              private http: HttpClient) {
    this.logger.info('(authentication.service) => constructor()');
  }

  public get isAuthenticated(): Observable<boolean> {
    const headerDict = {'Cache-Control': 'no-cache'};
    const requestOptions = { headers: new HttpHeaders(headerDict)};
   
    return this.http
     .get( DEFINITIONS.serverUrlUsersAuthenticate, requestOptions)
     .pipe(
      map((resp: any) => {
        //http response ok
        if( resp.user) {
          sessionStorage.setItem(DEFINITIONS.sessParEcadUser, resp.user);
        }
        sessionStorage.setItem(DEFINITIONS.sessParEcadUserLoggedIn, 'true');
        return true;
      }),
      catchError((err) => {
        //http error response
        this.logger.info('(authentication.service)[EXCEPTION] isAuthenticated(), error' + JSON.stringify(err));  //.info!!!
        return of(false);
      })
    );
  }

}
