import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DEFINITIONS } from '../definitions';

import { NavigationService } from '../_services/navigation.service';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private logger: NGXLogger,
              private authService: AuthenticationService,
              private navigationService: NavigationService) {
  }

  canActivate() {
    return this.checkAuthorization();
  }

  checkAuthorization(): Observable<boolean>{
    return this.authService.isAuthenticated.pipe( 
        map((isAuth: boolean) => {
          this.logger.info('(auth.guard) checkAuthorization, this.authService.isAuthenticated => ' + isAuth);
          if( !isAuth) {
            //login (and forward redirecturl to server by query param)
            const redirectUrl = window.location.origin + '/orders/administration' + window.location.search; 
            window.open(DEFINITIONS.serverUrlGeneratorLogin + '?redirecturl=' + btoa(redirectUrl), "_self")
            return false;
          } else {
            return true;
          }
        })
     )      
  } 
}
