import { Component, OnInit } from '@angular/core';
import { ModalWindowService } from '@bci-web-core/core';
import { OperationHelpComponent } from './../operation-help/operation-help.component';


@Component({
  selector: 'rexroth-help-menu',
  templateUrl: './help-menu.component.html',
  styleUrls: ['./help-menu.component.scss']
})
export class HelpMenuComponent implements OnInit {

  constructor(private modalWindowService: ModalWindowService) { 
  }

  ngOnInit(): void {
  }

  onOperationHelp() {
    this.modalWindowService.openDialogWithComponent(OperationHelpComponent, { width: '1100px' });
  }
}
