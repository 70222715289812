
<button *ngIf="isUserManagementAvailable==true" id="btnOpenUserMenu" bciIntegratedButton mat-icon-button [matMenuTriggerFor]="menuUser">
  <mat-icon [ngStyle]="{'color': isUserLoggedIn ? 'red':''}" 
            [matTooltip]="isUserLoggedIn ? getUsername : null"
            fontIcon="Bosch-Ic-user"></mat-icon>
</button>

<mat-menu #menuUser="matMenu">
  <button id="btnLogin" mat-menu-item (click)="onLoginClicked()" [disabled]="isUserLoggedIn">
      <mat-icon fontIcon="bosch-ic-login"></mat-icon>
    <span>{{'_Header.user.login'|translate}}</span>
  </button>
  <button id="btnLogout" mat-menu-item (click)="onLogoutClicked()" [disabled]="!isUserLoggedIn">
      <mat-icon fontIcon="bosch-ic-logout"></mat-icon>
    <span>{{'_Header.user.logout'|translate}}</span>
  </button>
</mat-menu>
