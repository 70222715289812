<bci-page-content class="full-space padding" cdkScrollable>

  <!-- Headlines (Device name, operation help link) -->
  <div id="generatorHeadline">
    <div class="headlineLeft">
      <h4 id="eplanGenTitle" style="margin-bottom: 0px;"><span translate>_GeneratorHeadline</span></h4>
      <br>
    </div>
  </div>

  <div id="flexBoxTopParent">
    <!--Input area: TypeCode Input-->
    <h6 id="typeCodeMatNumberHeadline" *ngIf="autocompTypeListCurrent && autocompTypeListCurrent.length !== 0"><span translate>_TypeCode-MaterialNumber</span><span *ngIf="materialNumber"> - {{materialNumber}}</span></h6>
    <h6 id="typeCodeHeadline" *ngIf="!autocompTypeListCurrent || autocompTypeListCurrent.length === 0"><span translate>_TypeCode</span></h6>
    <form id="flexBoxInputParent" (ngSubmit)="onSendTypeCode()">
      <mat-form-field id="typeCodeFormField" floatLabel="never">
        <span id="typeIdRootPart" matPrefix style="font-size: 16px; font-weight: bold;">{{typeCodeRootPart}}</span>
        <input id="typeIdInputPart"
               #typeIdInputPartVar="ngModel"
               ngControl="name"
               name="name"
               type="text"
               matInput
               [placeholder]="typeCodePlaceholder"
               [(ngModel)]="typeCodeInputPart"
               [disabled]="jobActive==true"
               (input)="checkInput()"  
               style="font-size: 16px"
               [matAutocomplete]="autoTypeCode"
               typeCodeMatNumberValidator
               [errorStateMatcher]="matcher">     

        <mat-error *ngIf="typeIdInputPartVar.hasError('typeCodeMatNumberValidator')">
          <span translate>_ValidateMatNumberWarn</span>
        </mat-error>
        
        <mat-autocomplete #autoTypeCode="matAutocomplete">
          <div *ngIf="jobActive==false">
            <div *ngIf="autocompleteTypeCodeMode; then autocompleteTypeCode else autocompleteMaterialNumber"></div>

            <ng-template #autocompleteTypeCode>
              <mat-option *ngFor="let option of autocompTypeList$ | async" (onSelectionChange)='onOptionSelected(option)' [value]="getTypeCodeInputPart(option.typecode)" >
                  {{option.typecode}}
              </mat-option>
            </ng-template>

            <ng-template #autocompleteMaterialNumber>
              <mat-option *ngFor="let option of autocompTypeList$ | async" (onSelectionChange)='onOptionSelected(option)' [value]="getTypeCodeInputPart(option.typecode)">
                  <b>{{option.materialnumber}}</b> [{{option.typecode}}]
              </mat-option>
            </ng-template>
          </div>
        </mat-autocomplete>

        <button mat-icon-button matSuffix id="btnTypeCodeInputPartClear" *ngIf="typeCodeInputPart" (mousedown)="onTypeCodeInputPartClear()"
          [disabled]="jobActive==true">
          <mat-icon fontIcon="bosch-ic-abort-frame"></mat-icon>
        </button>
      </mat-form-field>
    </form>
    <br>

    <!-- Device information, Generation status and results -->
    <div id="flexBoxMainAreaParent">

      <!-- Device picture -->
      <div id="flexPic">
        <img id="moduleDataImage" *ngIf="moduleDataService.ModuleDataLoaded" [src]="moduleData?.image"
          [alt]="moduleData?.moduleId" style="max-height:280px;max-width:100%;">
      </div>

      <!-- Device info text -->
      <div id="flexDesc">
        <h5 id="moduleDataTitle">{{moduleData?.title}}</h5>
        <p id="moduleDataDescription" [innerHTML]="moduleData?.description"></p>
      </div>

      <!-- Status and results -->
      <div id="flexStatus">
        <div>

          <div id="flexStatusGenerate">
            <button mat-raised-button color="primary" id="btnGenerate"
              [disabled]="isBtnGenerateDisabled()"
              (click)="onSendTypeCode()"><span translate>_Generate</span>
            </button>

            <div id="flexStatusJobState">
              <mat-spinner *ngIf="jobStatus=='Running' || jobStatus=='Queued'" mode="indeterminate" [diameter]="20"
                style="position:relative; left: 6px;">
              </mat-spinner>
              <i *ngIf="jobError==true" class="material-icons"
                style="position:relative; left: 6px;font-size: 24px; color:red;">error_outline</i>
              <i *ngIf="jobSuccess==true" class="material-icons"
                style="position:relative; left: 6px;font-size: 24px; color:green;">check_circle</i>
              <span style="position:relative; left: 10px;">{{jobStatus | translate}}</span>
            </div>

            <!-- Results: buttons 'Artikeldaten importieren'/'Makro platzieren' -->
            <div *ngIf="!showDownloads && jobSuccess">
              <!-- <div> -->
              <button mat-raised-button color="primary" id="btnImport" [attr.href]="this.getImportLink()"
                (click)="openLink('btnImport', false)"><span translate>_Import</span></button>
              <button mat-raised-button color="primary" id="btnPlaceMakro" [attr.href]="this.getMakroLink()"
                (click)="openLink('btnPlaceMakro', false)"><span translate>_PlaceMarko</span></button>
              <!-- <hr size="1" color="darkgrey" /> -->
            </div>

            <!-- Results: list with download files -->
            <div *ngIf="jobSuccess && showDownloads">
              <div id="downloadFileList">
                <h6 style="text-align: center; font-weight: bold; position:relative; top: 10px;">Downloads</h6>
                <mat-list>
                  <mat-list-item *ngFor="let d of downloads">
                    <a id="downloadLink" *ngIf="d.title.length>1"  [attr.title]="d.title" (click)="onDownloadSingleFile(d.links.file.href)" style="font-size: 14px">{{d.title}}</a>
                    <a id="downloadLink" *ngIf="d.title.length<2"  [attr.title]="d.title" style="font-size: 14px">{{d.title}}</a>
                  </mat-list-item>
                </mat-list>
              </div>
            </div>
          </div>

          <div id="flexStatusInfoLinks">
            <!--Panel rechts, unterer Teil: Link auf Produktkatalog und Supportanfrage-->
            <button mat-button id="btnProductInfo" [disabled]="moduleData?.link==''" target="_blank"
              [attr.href]="moduleData?.link" (click)="openLink('btnProductInfo', true)">
              <span translate>_ProductInfo</span>
            </button>
            <button mat-button id="btnSupportRequest" (click)="sendSupportRequest()">
              <span translate>_NavSupport</span>
            </button>
          </div>


        </div>
      </div>
    </div>

  </div>


</bci-page-content>
